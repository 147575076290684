<template>
  <router-link
    :to="linkTarget ? { name: linkTarget } : {}"
    style="text-decoration:none;"
  >
    <v-card
      :number-header="numberHeader"
      :rate-change="rateChange"
      elevation="0" 
      class="card-summary d-flex flex-column pa-6"
      :class="{ 'action': !!linkTarget }"
    >
      <section class="d-flex justify-space-between">
        <span class="card-header pa-2">{{ cardHeader }}</span>
        <img
          :src="require(`@/assets/${image}`)"
          alt="Action icon for card"
        >
      </section>
      <section class="mx-2 mb-2 mt-3">
        <span class="card-data">{{ numberHeader }}</span>
        <div
          v-if="showRateChange"
          class="rate-changes d-flex justify-start mt-2"
        >
          <span
            v-show="rateChange !== 0"
            class="percent-change mr-2"
            :class="{ 'negative': rateChange < 0 }"
          >{{ rateChange > 0 ? '+' + rateChange : rateChange }}%</span>
          {{ rateChangeText }}
        </div>
      </section>
    </v-card>
  </router-link>
</template>

<script>
export default {
  name: 'CardSummary',
  props: {
    image: {
      type: String,
      required: true
    },
    cardHeader: {
      type: [String, Number],
      required: true
    },
    numberHeader: {
      type: [String, Number],
      required: true
    },
    rateChange: {
      type: [String, Number],
      default: ''
    },
    linkTarget: {
      type: [String],
      default: null
    },
    showRateChange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    rateChangeText() {
      if (this.rateChange < 0) {
        return 'less than last week'    // TODO determine if data is calculated per week or month
      } else if (this.rateChange > 0) {
        return 'more than last week'
      }
      return 'No change in the past week'
    }
  }
}
</script>
<style lang="scss" scoped>

.card-summary {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  transition-property: box-shadow, border-color;
  transition-duration: 0.1s;

  &.action {
    cursor: pointer;
  
    &:hover {
      border-color: #ff9175;
      box-shadow: 0px 0.5px 0.5px #ff9175;
    }
  }

  &:not(.action) {
    // Router link will automatically make the cursor pointer, override that
    cursor: default;
  }

  span.card-header {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
  span.card-data {
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
    color: #100E0E;
  }

  .rate-changes {
    height: 26px;
    line-height: 26px;
    font-weight: 400;
    font-size: 14px;
    color: #615C5C;

    .percent-change {
      display: inline-block;
      height: 26px;
      padding: 0px 6px;
      font-weight: 600;
      border-radius: 8px;

      // Defaults to positive style, apply negative class explicitly
      color: #3BA565;
      background: rgba(27, 189, 92, 0.1);

      &.negative {
        color: #F82F5F;
        background: rgba(248, 47, 95, 0.1);
      }
    }
  }

}
</style>